import React, { useState, useCallback, useEffect } from 'react'
import { Notification } from 'react-bulma-components';
import { NOTIFICATION_DURATION_IN_SECONDS } from './utils.js'
import './InGameNotification.css';

const InGameNotification = ({msg}) => {
  const [isShown, setIsShown] = useState(false)
  let notificationDelayTimeout = null

  useEffect(() => {
    if (msg !== null) {
      setIsShown(true)
      clearTimeout(notificationDelayTimeout)
      notificationDelayTimeout = setTimeout(() => {
        setIsShown(false)
      }, NOTIFICATION_DURATION_IN_SECONDS * 1000)
    }
  }, [msg])

  return (
    <Notification color={(msg + '').toLowerCase().includes('incorrect') ? 'warning' : 'primary'} className={isShown ? '' : 'is-invisible'}>{msg || '0'}</Notification>
  )
}

export default InGameNotification