import React, { useState, useCallback } from 'react'
import { Button, Container, Section, Notification, Columns } from 'react-bulma-components';
import { useNavigate } from "react-router-dom";
import './BingoBoard.css';

const BingoBoard = ({size, data, onSelect}) => {
  return (
    <>
    <Columns centered="true" className="bingo-board" multiline="true">
      {data && data.map((item, index) => {
        return (
          <Columns.Column centered="true" size={12/size}><Button rounded={true} outlined={item !== 'x'} size="large" color="primary" onClick={() => {onSelect(item, index)}}>{item}</Button></Columns.Column>
        )
      })}
    </Columns>
    </>
  )
}

export default BingoBoard