module.exports.NOTIFICATION_DURATION_IN_SECONDS = 2.3
module.exports.CELEBRATION_EMOJIS = ['😀', '😃', '🥰', '😍', '🤩', '🥳', '🌟', '🔥', '💯', '🎉', '😻', '❤️', '💪', '👏']
module.exports.positiveNotificationMsg = (msg) => {
  return this.CELEBRATION_EMOJIS[Math.floor(Math.random() * this.CELEBRATION_EMOJIS.length)] + ' ' + msg + ' ' + this.CELEBRATION_EMOJIS[Math.floor(Math.random() * this.CELEBRATION_EMOJIS.length)]
}

module.exports.generateMathQuestion = (level) => {
  switch (level) {
    case 4: return this.generateLevel4Math()
    case 3: return [this.randomIntFromInterval(10, 99), this.randomIntFromInterval(1, 9)]
    case 2: return [this.randomIntFromInterval(10, 20), this.randomIntFromInterval(1, 9)]
    case 1:
    default:
      const num1 = this.randomIntFromInterval(1, 9)
      const num2 = this.randomIntFromInterval(1, 9)
      return [Math.max(num1, num2), Math.min(num1, num2)]
  }
}

module.exports.randomIntFromInterval = (min, max) => { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

module.exports.speakOutLoud = (text) => {
  let utterance = new SpeechSynthesisUtterance(text);
  // let voices = window.speechSynthesis.getVoices()
  // utterance.voice = voices[10]
  speechSynthesis.speak(utterance);
}

module.exports.ARITHMETIC_MODES_CONFIG = {
  'Addition': {
    'sign': '+',
    computeAnswer: (val1, val2) => { return parseInt(val1) + parseInt(val2) },
    'speakOutSign': 'plus'
  },
  'Subtraction': {
    'sign': '-',
    computeAnswer: (val1, val2) => { return parseInt(val1) - parseInt(val2) },
    'speakOutSign': 'minus'
  },
  'Multiplication': {
    'sign': 'x',
    computeAnswer: (val1, val2) => { return parseInt(val1) * parseInt(val2) },
    'speakOutSign': 'times'
  },
}


module.exports.generateLevel4Math = () => {
  let num1, num2, sum, sumOfLastDigits
  do {
    num1 = this.randomIntFromInterval(1, 99)
    num2 = this.randomIntFromInterval(1, 99)
    sum = num1 + num2
    sumOfLastDigits  = parseInt(String(num1).slice(-1)) + parseInt(String(num2).slice(-1))
  } while (sum > 100 || sumOfLastDigits >= 10)
  return [num1, num2]
}
