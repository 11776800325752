import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import './NavbarActions.css'

const NavbarActions = ({isGameStarted, handleEndGame}) => {
  const navigate = useNavigate();

  return (
    <>
    <div className={isGameStarted ? "emoji nav-button back-button is-hidden" : "emoji nav-button back-button"} onClick={() => navigate(-1)}>⬅️</div>
    <div className={isGameStarted ? "emoji nav-button exit-button" : "emoji nav-button exit-button is-hidden"} onClick={() => handleEndGame()}>❌</div>
    </>
  )
}

export default NavbarActions