import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { generateMathQuestion, positiveNotificationMsg, ARITHMETIC_MODES_CONFIG, NOTIFICATION_DURATION_IN_SECONDS, speakOutLoud } from './utils.js'
import BingoBoard from './BingoBoard';
import InGameNotification from './InGameNotification.js';
import NavbarActions from './NavbarActions.js';

const BingoMathApp = ({mode, size}) => {
  const [questions, setQuestions] = useState(null)
  const [notificationMsg, setNotificationMsg] = useState(null)
  const [boardData, setBoardData] = useState(null)
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null)
  const [isGameStarted, setIsGameStarted] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    if (questions === null) {
      let thisQuestions = []
      let firstLevelIndex = parseInt(size*size*0.7)
      // 70% level 1
      for (let i = 0; i < firstLevelIndex; ++i) {
        thisQuestions.push(generateMathQuestion(1).concat([i]))
      }
      // 30% level 2
      for (let i = firstLevelIndex; i < size*size; ++i) {
        thisQuestions.push(generateMathQuestion(2).concat([i]))
      }
      setQuestions(thisQuestions)

      setNotificationMsg(null)
      setIsGameStarted(true)

      chooseNextQuestion(thisQuestions)
    }
  }, [])

  const handleEndGame = useCallback(() => {
    setIsGameStarted(false)
    navigate(-1)
  }, [notificationMsg])

  const chooseNextQuestion = useCallback((thisQuestions) => {
    // Filter out to only remaining questions
    const remainingQuestions = thisQuestions.filter(x => x[0] !== 'x')
    if (remainingQuestions.length === 0) {
      speakOutLoud("Great job!")
      setNotificationMsg(positiveNotificationMsg('Great job!'))
      setTimeout(handleEndGame, NOTIFICATION_DURATION_IN_SECONDS * 1000)
    }

    const thisSelectedQuestionIndex = remainingQuestions[Math.floor(Math.random() * remainingQuestions.length)][2]
    setSelectedQuestionIndex(thisSelectedQuestionIndex)
    speakOutLoud(thisQuestions[thisSelectedQuestionIndex][0] + ' ' + ARITHMETIC_MODES_CONFIG[mode]['speakOutSign'] + ' ' + thisQuestions[thisSelectedQuestionIndex][1])

    setBoardData(constructBoardData(mode, thisQuestions))
  }, [questions, selectedQuestionIndex, boardData])

  const onBoardClick = useCallback((val, index) => {
    if (val === ARITHMETIC_MODES_CONFIG[mode].computeAnswer(questions[selectedQuestionIndex][0], questions[selectedQuestionIndex][1])) {
      // Correct
      speakOutLoud("That's correct!")
      setNotificationMsg(positiveNotificationMsg('Correct!'))
      let thisQuestions = questions.slice()
      thisQuestions[index][0] = 'x'
      thisQuestions[index][1] = 'x'
      console.log({thisQuestions})
      setQuestions(thisQuestions)
      chooseNextQuestion(thisQuestions)
    } else {
      // Incorrect
      speakOutLoud("Try again")
      setNotificationMsg('Try again')
    }
  }, [questions, boardData, notificationMsg])

  return (
    <>
    <NavbarActions isGameStarted={isGameStarted} handleEndGame={() => handleEndGame() } />
    <InGameNotification msg={notificationMsg} color="primary" />
    <h1>{questions && questions[selectedQuestionIndex][0]} {ARITHMETIC_MODES_CONFIG[mode]['sign']} {questions && questions[selectedQuestionIndex][1]}</h1>
    <BingoBoard size={size} data={boardData} onSelect={(val, index) => onBoardClick(val, index)} />
    </>
  )
}

function constructBoardData(mode, questionsAr) {
  let thisBoardData = []
  for (let q of questionsAr) {
    if (q[0] === 'x') {
      thisBoardData.push('x')
    } else {
      thisBoardData.push(ARITHMETIC_MODES_CONFIG[mode].computeAnswer(q[0], q[1]))
    }
  }
  return thisBoardData
}

export default BingoMathApp